import {useMutation, useQueryClient} from 'react-query';
import {
    kontaktpersonerUpdateKontaktperson,
    KontaktpersonForUpdateDto,
} from '@norkart/nkapi-arealplaner-api';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import useErrorHandler from '../errors/useErrorHandler';

export default function useUpdateKontaktperson() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const {authorizedClient} = useArealplanerApiClientContext();
    const {notify} = useErrorHandler();

    return useMutation(
        async ({
            kontaktperson,
            id,
        }: {
            kontaktperson: KontaktpersonForUpdateDto;
            id: number;
        }) => {
            const {data, error} = await kontaktpersonerUpdateKontaktperson({
                client: authorizedClient,
                path: {kundeId, id},
                body: kontaktperson,
            });
            if (error || !data) {
                throw new Error('Error updating kontaktperson');
            }
            return data;
        },
        {
            onError: (error: Error) => {
                notify(error.message);
            },
            onSuccess: () => {
                queryClient.invalidateQueries(['kontaktpersoner', {kundeId}]);
            },
        }
    );
}
