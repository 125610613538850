import {useMutation, useQueryClient} from 'react-query';
import {
    organisasjonspersonerCreateOrganisasjonsperson,
    OrganisasjonspersonForCreationDto,
} from '@norkart/nkapi-arealplaner-api';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';
import useErrorHandler from '../errors/useErrorHandler';

export default function useCreateOrganisasjonsPerson() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const {authorizedClient} = useArealplanerApiClientContext();
    const {notify} = useErrorHandler();

    return useMutation(
        async (organisasjonsPerson: OrganisasjonspersonForCreationDto) => {
            const {data, error} =
                await organisasjonspersonerCreateOrganisasjonsperson({
                    client: authorizedClient,
                    path: {kundeId},
                    body: organisasjonsPerson,
                });
            if (error || !data) {
                throw new Error('Error creating organisasjonsperson');
            }
            return data;
        },
        {
            onError: (error: Error) => {
                notify(error.message);
            },
            onSuccess: () => {
                queryClient.invalidateQueries([
                    'organisasjonspersoner',
                    {kundeId},
                ]);
            },
        }
    );
}
