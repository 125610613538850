import LoadingSpinner from '../../../../components/LoadingSpinner';
import ReactTable from '../../../../components/ReactTable';
import useKostraRapporter from '../../../../hooks/kostra/useKostraRapporter';
import KostraColumns from './KostraColumns';

export default function KostraTable() {
    const reports = useKostraRapporter();
    const columns = KostraColumns();
    if (reports.isLoading) return <LoadingSpinner />;
    return (
        <ReactTable columns={columns} data={reports.data ? reports.data : []} />
    );
}
