import {Link} from '@mui/material';
import {styled} from '@mui/material/styles';
import {ToiLinkProps} from './ToiLink';

export const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => !['definition', 'inline'].includes(prop.toString()),
})<ToiLinkProps>(({theme, definition, inline, color = 'primary'}) => {
  const linkColor = definition ? 'secondary' : color;

  return {
    width: 'fit-content',
    lineHeight: '22px',
    display: inline || definition ? 'inline-flex' : 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    verticalAlign: 'top',

    ...{
      primary: {
        color: theme.vars.toi.palette.links.default,
        textDecorationColor: theme.vars.toi.palette.links.default,

        '&:hover': {
          color: theme.vars.toi.palette.links.hover,
        },
      },

      secondary: {
        color: theme.vars.toi.palette.links.black,
        textDecorationColor: theme.vars.toi.palette.links.black,

        '&:hover': {
          color: theme.vars.toi.palette.links.blackHover,

          '& path': {
            stroke: theme.vars.toi.palette.links.blackHover,
          },
        },
      },
    }[linkColor],

    '&.MuiLink-button': {
      verticalAlign: 'baseline',
    },

    '& span': {
      alignContent: 'center',
    },

    '& .ToiLink-startIcon, & .ToiLink-endIcon': {
      verticalAlign: 'baseline',
      display: 'flex',
      alignItems: 'center',
    },

    '& .ToiLink-startIcon': {
      marginRight: theme.toi.spacing.xs,
    },

    '& .ToiLink-endIcon': {
      marginLeft: theme.toi.spacing.xs,
    },

    '&:focus-visible': {
      outline: 'none',
      '& span': {
        outline: '1px dashed',
        outlineColor: theme.vars.toi.palette.focus.border,
        outlineOffset: '2px',
        borderRadius: '2px',
      },
    },

    ...(definition && {
      textDecorationStyle: 'dashed',
      textDecorationThickness: '1px',

      '&:hover,&:focus': {
        background: theme.vars.toi.palette.signal.background.normal,
      },

      '&[aria-labelledby]': {
        backgroundColor: theme.vars.toi.palette.backgrounds.component.selected,
      },
    }),
  };
});
