import {Autocomplete, Grid} from '@mui/material';
import * as React from 'react';
import DateRangePickerMui, {
    StartOrEndDate,
} from '../../../../components/DatePicker/DateRangePickerMui/DateRangePickerMui';
import {ToiLikeStyledTextField} from '../../../../components/TextField/ToiLikeStyledTextField';
import {Planstatus, Plantype, Vedtakstype} from '../../../../hooks/meta/types';
import {useFilterQL} from '../ReportTools/FilterQL';
import {StyledMuiTextFieldSearch} from '../ReportTools/StyledComponents/StyledMuiTextFieldSearch';

type FilterDispensasjonerProps = {
    //filterFn: (any) => any
    planstatuser?: Planstatus[];
    plantyper?: Plantype[];
    vedtaksstatuser?: Vedtakstype[];
    setFilterValues: React.Dispatch<any>;
    clearFilter: boolean;
};

type SelectedFiltersProps = {
    vedtaksstatus?: Vedtakstype[];
    planstatus?: Planstatus[];
    plantype?: Plantype[];
    dispensasjonerBeskrivelse?: string;
    fraDato?: Date;
    tilDato?: Date;
};

export function DispensasjonerFilter({
    vedtaksstatuser,
    plantyper,
    planstatuser,
    setFilterValues,
    clearFilter,
}: FilterDispensasjonerProps) {
    const filterQl = useFilterQL();
    const [selectedFilters, setSelectedFilters] = React.useState<
        SelectedFiltersProps | undefined
    >();

    const handleDatoChange = (dates: StartOrEndDate | undefined) => {
        setSelectedFilters({
            ...selectedFilters,
            tilDato: dates?.end,
            fraDato: dates?.start,
        });
    };

    //Lager array av objekter som skal brukes som filtere
    const handleFiltering = React.useCallback(
        (filters: SelectedFiltersProps | undefined) => {
            const newFilter: any[] = [];
            filters?.vedtaksstatus &&
                filters?.vedtaksstatus.length !== 0 &&
                newFilter.push({
                    or: filters.vedtaksstatus.map((vs) => ({
                        vedtakId: {eq: vs.id},
                    })),
                });

            filters?.planstatus &&
                filters.planstatus.length !== 0 &&
                newFilter.push({
                    or: filters.planstatus.map((ps) => ({
                        arealplan: {
                            planStatusId: {eq: ps.id},
                        },
                    })),
                });

            filters?.plantype &&
                filters.plantype.length !== 0 &&
                newFilter.push({
                    or: filters.plantype.map((pt) => ({
                        arealplan: {
                            planTypeId: {eq: pt.id},
                        },
                    })),
                });

            filters?.fraDato &&
                newFilter.push({
                    vedtaksdato: {
                        gte: filters.fraDato.toJSON(),
                    },
                });

            filters?.tilDato &&
                newFilter.push({
                    vedtaksdato: {
                        lte: filters.tilDato.toJSON(),
                    },
                });

            filters?.dispensasjonerBeskrivelse &&
                filters.dispensasjonerBeskrivelse !== '' &&
                newFilter.push({
                    beskrivelse: {contains: filters?.dispensasjonerBeskrivelse},
                });

            if (newFilter.length === 0) filterQl.updateFilter(undefined);
            else filterQl.updateFilter(newFilter);
        },
        [selectedFilters]
    );

    //Cleare beskrivelse feltet
    const clearSearch = () => {
        setSelectedFilters({...selectedFilters, dispensasjonerBeskrivelse: ''});
    };

    //Debounce for beskrivelse feltet
    React.useMemo(() => {
        handleFiltering(selectedFilters);
    }, [
        selectedFilters?.vedtaksstatus,
        selectedFilters?.planstatus,
        selectedFilters?.plantype,
        selectedFilters?.fraDato,
        selectedFilters?.tilDato,
    ]);

    React.useMemo(() => {
        if (
            selectedFilters?.dispensasjonerBeskrivelse === '' ||
            !selectedFilters?.dispensasjonerBeskrivelse
        ) {
            return handleFiltering(selectedFilters);
        }

        const filterTimeout = setTimeout(() => {
            handleFiltering(selectedFilters);
        }, 500);

        return () => clearTimeout(filterTimeout);
    }, [selectedFilters?.dispensasjonerBeskrivelse]);

    //Setter oppdaterte filterverdier så det kan brukes som variabler i graphql request
    React.useEffect(() => {
        setFilterValues(filterQl.filterReturn);
    }, [filterQl.filterReturn]);

    React.useEffect(() => {
        if (clearFilter) setSelectedFilters(undefined);
    }, [clearFilter]);

    return (
        <div style={{marginTop: 10}}>
            <Grid direction='row' container={true}>
                <Grid item={true}>
                    <Autocomplete
                        size='small'
                        options={vedtaksstatuser ? vedtaksstatuser : []}
                        sx={{
                            width: 400,
                        }}
                        limitTags={1}
                        ChipProps={{
                            color: 'primary',
                            variant: 'outlined',
                            sx: {borderRadius: 1},
                        }}
                        componentsProps={{
                            popper: {
                                color: 'secondary',
                                sx: {paddingTop: 5, boxShadow: 3},
                            },
                        }}
                        color='secondary'
                        multiple={true}
                        value={
                            selectedFilters?.vedtaksstatus
                                ? selectedFilters.vedtaksstatus
                                : []
                        }
                        getOptionLabel={(option) =>
                            `${option.id} - ${option?.beskrivelse}`
                        }
                        onChange={(event, newValue) =>
                            setSelectedFilters({
                                ...selectedFilters,
                                vedtaksstatus: newValue,
                            })
                        }
                        renderInput={(params) => (
                            <ToiLikeStyledTextField
                                {...params}
                                size='small'
                                label='Vedtaksstatus'
                                variant='outlined'
                                color='secondary'
                            />
                        )}
                    />
                    <Autocomplete
                        size='small'
                        options={planstatuser ? planstatuser : []}
                        sx={{
                            width: 400,
                        }}
                        limitTags={1}
                        ChipProps={{
                            color: 'primary',
                            variant: 'outlined',
                            sx: {borderRadius: 1},
                        }}
                        componentsProps={{
                            popper: {
                                color: 'secondary',
                                sx: {paddingTop: 5, boxShadow: 3},
                            },
                        }}
                        color='secondary'
                        multiple={true}
                        value={
                            selectedFilters?.planstatus
                                ? selectedFilters.planstatus
                                : []
                        }
                        getOptionLabel={(option) =>
                            `${option.id} - ${option?.beskrivelse}`
                        }
                        onChange={(event, newValue) =>
                            setSelectedFilters({
                                ...selectedFilters,
                                planstatus: newValue,
                            })
                        }
                        renderInput={(params) => (
                            <ToiLikeStyledTextField
                                {...params}
                                size='small'
                                label='Planstatus'
                                variant='outlined'
                                color='secondary'
                            />
                        )}
                    />
                    <Autocomplete
                        size='small'
                        options={plantyper ? plantyper : []}
                        sx={{
                            width: 400,
                        }}
                        limitTags={1}
                        ChipProps={{
                            color: 'primary',
                            variant: 'outlined',
                            sx: {borderRadius: 1},
                        }}
                        componentsProps={{
                            popper: {
                                color: 'secondary',
                                sx: {paddingTop: 5, boxShadow: 3},
                            },
                        }}
                        color='secondary'
                        multiple={true}
                        value={
                            selectedFilters?.plantype
                                ? selectedFilters.plantype
                                : []
                        }
                        getOptionLabel={(option) =>
                            `${option.id} - ${option?.beskrivelse}`
                        }
                        onChange={(event, newValue) =>
                            setSelectedFilters({
                                ...selectedFilters,
                                plantype: newValue,
                            })
                        }
                        renderInput={(params) => (
                            <ToiLikeStyledTextField
                                {...params}
                                size='small'
                                label='Plantype'
                                variant='outlined'
                                color='secondary'
                            />
                        )}
                    />
                </Grid>
                <Grid sx={{paddingLeft: 5}} item={true}>
                    <StyledMuiTextFieldSearch
                        color='secondary'
                        fullWidth={true}
                        size='small'
                        sx={{width: 400, paddingLeft: 3}}
                        variant='outlined'
                        label='Beskrivelse'
                        handleClearSearch={() => clearSearch()}
                        active={
                            selectedFilters?.dispensasjonerBeskrivelse
                                ? true
                                : false
                        }
                        onChange={(e) =>
                            setSelectedFilters({
                                ...selectedFilters,
                                dispensasjonerBeskrivelse: e.target.value,
                            })
                        }
                        onKeyUp={(e) => {
                            if (e.key === 'Escape')
                                setSelectedFilters({
                                    ...selectedFilters,
                                    dispensasjonerBeskrivelse: '',
                                });
                        }}
                        value={
                            selectedFilters?.dispensasjonerBeskrivelse
                                ? selectedFilters.dispensasjonerBeskrivelse
                                : ''
                        }
                    />
                    <div style={{paddingLeft: 6}}>
                        <DateRangePickerMui
                            textFieldProps={{sx: {width: 400, paddingRight: 3}}}
                            onChange={(d) => {
                                handleDatoChange(d);
                            }}
                            label='Vedtaksdato'
                            startDate={selectedFilters?.fraDato}
                            endDate={selectedFilters?.tilDato}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
