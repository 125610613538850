import {IconButtonProps} from '@mui/material';
import {forwardRef} from 'react';
import {CloseIcon} from '@norkart/toi-icons';
import {StyledIconButton} from './StyledCloseButton';

export const ToiCloseButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => (
  <StyledIconButton ref={ref} tabIndex={0} aria-label='Lukk' {...props}>
    <CloseIcon />
  </StyledIconButton>
));
