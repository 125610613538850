import {Card as MuiCard, styled} from '@mui/material';

export const StyledCard = styled(MuiCard)(({theme}) => {
  return {
    gap: theme.toi.spacing.s,
    position: 'relative',
    borderRadius: '4px',
    border: `1px solid ${theme.vars.toi.palette.border.component}`,
    background: theme.vars.toi.palette.backgrounds.component.fill,
    height: 'fit-content',
    boxShadow: 'none',
  };
});
