import {toiColors} from '@norkart/toi-colors';
import {ToiStack, ToiTypography, ToiMarkdown} from '@norkart/toi-components';
import {chatHistoryItem} from '../cache/chatHistory';
import ChatStarsGraphic from '../assets/images/chat_stars_graphic.svg';
import {ConversationType} from '../types';
import {ReferenceChatItem} from './ReferenceChatItem';

export const ChatWindow = (props: {
    botName: string;
    chatHistory: chatHistoryItem[];
    conversationConfig: ConversationType[];
    displaySearchedIndicesText?: boolean;
}) => {
    const {
        botName,
        chatHistory,
        conversationConfig,
        displaySearchedIndicesText = true,
    } = props;

    const getSearchIndexName = (id: string) => {
        const searchIndex = conversationConfig
            .map((c) => c.searchIndices)
            .flat()
            .find((si) => si.id === id);
        return searchIndex?.name ? searchIndex?.name?.toLowerCase() : id;
    };

    const getSearchText = (
        searchIndices: string[],
        hideLongText = true,
        maxLength = 60
    ) => {
        const fullText = searchIndices
            .map((searchIndex) => getSearchIndexName(searchIndex))
            .join(', ');

        return fullText.length > maxLength && hideLongText
            ? fullText.substring(0, maxLength) + '...'
            : fullText;
    };

    return (
        <ToiStack gap={4}>
            {chatHistory.map((chatElement, i) => {
                const isAi = chatElement.type === 'ai';
                return (
                    <ToiStack
                        p={1.5}
                        key={i}
                        sx={isAi ? {} : {backgroundColor: toiColors.purple[20]}}
                        alignSelf={'start'}
                    >
                        <ToiStack
                            direction='row'
                            alignItems={'center'}
                            gap={1.5}
                        >
                            {isAi ? (
                                <img
                                    alt='Nora-logo'
                                    src={ChatStarsGraphic}
                                    style={{
                                        height: 35,
                                        marginLeft: '-50px',
                                    }}
                                />
                            ) : null}

                            <ToiTypography
                                color='primary'
                                variant='body2'
                                sx={{fontWeight: 600}}
                            >
                                {isAi ? botName : 'Deg'}
                            </ToiTypography>
                            {isAi &&
                                displaySearchedIndicesText &&
                                chatElement.searchIndices.length > 0 && (
                                    <ToiTypography
                                        color='primary'
                                        variant='body3'
                                        sx={{fontWeight: 600}}
                                        title={getSearchText(
                                            chatElement.searchIndices,
                                            false
                                        )}
                                    >
                                        {`– Søkte i ${getSearchText(
                                            chatElement.searchIndices
                                        )}
                                        og fant:`}
                                    </ToiTypography>
                                )}
                        </ToiStack>
                        {chatElement.content &&
                            (isAi ? (
                                <ToiMarkdown>{chatElement.content}</ToiMarkdown>
                            ) : (
                                <ToiTypography fontWeight='semibold'>
                                    {chatElement.content}
                                </ToiTypography>
                            ))}
                        {chatElement.reference &&
                            chatElement.reference.map((ref, i) => (
                                <ReferenceChatItem
                                    key={i}
                                    title={ref.title}
                                    contentText={ref.contentText}
                                    reference={ref.reference}
                                />
                            ))}
                    </ToiStack>
                );
            })}
        </ToiStack>
    );
};
