import * as React from 'react';
import BackIcon from '@mui/icons-material/ArrowBack';
import {
    Box,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    styled,
    Toolbar,
    Typography,
} from '@mui/material';
import {useDispatch} from 'react-redux';
import {Link, Route, Routes, useNavigate} from 'react-router-dom';
import qs from 'query-string';
import MainContent from '../../../components/DrawerComponents/MainComponent';
import useSelectedKundeId from '../../../hooks/kunder/useSelectedKundeId';
import {resetMapState} from '../../../store/map/actions';
import useCan from '../../../utils/auth/useCan';
import {setTitle} from '../../../utils/setTitle';
import useSearch from '../../../hooks/search/useSearch';
import useMeta from '../../../hooks/meta/useMeta';
import useReportTypes from './useReportTypes';

const drawerWidth = 240;

function Reports() {
    const can = useCan();
    const kundeId = useSelectedKundeId();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const reportTypes = useReportTypes();
    const search = useSearch();
    setTitle('Rapporter');
    const meta = useMeta();

    const handleBackToSearch = () => {
        dispatch(resetMapState());
        navigate({
            pathname: `/${kundeId}/arealplaner/search`,
            search: qs.stringify({...search.searchState.urlSearchParams}),
        });
    };

    const activeRoute = (linkPath: string) => {
        return window.location.pathname === linkPath;
    };

    const StyledLink = styled(Link)(({theme}) => ({
        textDecoration: 'none',
        color: theme.palette.text.primary,
    }));

    if (can.view && reportTypes.filter((rt) => rt.tilgang).length > 0)
        return (
            <Box
                sx={{
                    display: 'flex',
                }}
            >
                <Drawer
                    variant='permanent'
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiPaper-root': {
                            width: drawerWidth,
                        },
                    }}
                >
                    <Toolbar />
                    <Box
                        sx={(theme) => ({
                            flexGrow: 1,
                        })}
                    >
                        <List>
                            <ListItem
                                button={true}
                                onClick={handleBackToSearch}
                            >
                                <ListItemIcon>
                                    <BackIcon />
                                </ListItemIcon>
                                <ListItemText primary='Plansøk' />
                            </ListItem>
                        </List>
                        <Divider />
                        <List>
                            {reportTypes
                                .filter((rt) => rt.tilgang)
                                .map((report) => (
                                    <StyledLink
                                        key={report.Navn}
                                        to={report.to}
                                    >
                                        <ListItem
                                            button={true}
                                            selected={activeRoute(report.to)}
                                            sx={{
                                                '&.Mui-selected': {
                                                    fontWeight: 500,
                                                },
                                            }}
                                        >
                                            {report.Navn}
                                        </ListItem>
                                    </StyledLink>
                                ))}
                        </List>
                    </Box>
                </Drawer>
                <MainContent>
                    <Routes>
                        {reportTypes
                            .filter((rt) => rt.tilgang)
                            .map((reporttype) => (
                                <Route
                                    key={reporttype.Id}
                                    path={reporttype.path}
                                    element={<reporttype.component />}
                                />
                            ))}
                    </Routes>
                </MainContent>
            </Box>
        );

    return (
        <Typography>
            Du mangler rettigheter til å se denne siden/har ingen rapporter å
            vise.
        </Typography>
    );
}

export default Reports;
