import {Tooltip as MuiTooltip, styled, TooltipProps as MuiTooltipProps} from '@mui/material';
import React, {forwardRef} from 'react';

export type ToiTooltipProps = MuiTooltipProps;

export const ToiTooltip = styled(
  forwardRef<HTMLDivElement, ToiTooltipProps>(({className, ...props}, ref) => (
    <MuiTooltip {...props} classes={{popper: className}} ref={ref} />
  )),
)(({theme}) => ({
  // https://mui.com/material-ui/react-tooltip/#customization
  '& .MuiTooltip-tooltip': {
    backgroundColor: theme.vars.toi.palette.backgrounds.component.overlay,
    color: theme.vars.toi.palette.text.default,
    boxShadow: theme.shadows[2],
    borderRadius: '4px',
    ...theme.typography.body2,
  },

  '& .MuiTooltip-arrow': {
    '&::before': {
      backgroundColor: theme.vars.toi.palette.backgrounds.component.overlay,
      boxShadow: theme.shadows[2],
    },
  },
}));

/**
 * @deprecated Renamed to ToiTooltip
 */
export {ToiTooltip as ToiToolTip};
