import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {useState} from 'react';
import {KontaktpersonDto} from '@norkart/nkapi-arealplaner-api';
import GenericTable from '../../../components/GenericTable';
import {
    GTableAction,
    GTableColumn,
} from '../../../components/GenericTable/GenericTable';
import {useKontaktpersoner} from '../../../hooks/kontaktpersoner/useKontaktpersoner';
import useMeta from '../../../hooks/meta/useMeta';
import ActionButton from '../../../components/ActionButtons/ActionButton';
import KontaktpersonDialog from './KontaktpersonDialog';
import ConfirmDeleteKontaktpersonDialog from './ConfirmDeleteKontaktpersonDialog';

function Kontaktpersoner() {
    const {data: kontaktpersoner} = useKontaktpersoner();
    const {data: metaData} = useMeta();

    const [kontaktpersonDialog, setKontaktpersonDialog] = useState(false);
    const [kontaktpersonDeleteDialog, setKontaktpersonDeleteDialog] =
        useState(false);

    const [kontaktperson, setKontaktperson] = useState<
        KontaktpersonDto | undefined
    >(undefined);

    const handleAddButton = () => {
        setKontaktpersonDialog(true);
    };

    const handleEdit = (kontaktperson: KontaktpersonDto) => {
        setKontaktperson(kontaktperson);
        setKontaktpersonDialog(true);
    };

    const handleDelete = (kontaktperson: KontaktpersonDto) => {
        setKontaktperson(kontaktperson);
        setKontaktpersonDialog(false);
        setKontaktpersonDeleteDialog(true);
    };

    const handleDialogClose = () => {
        setKontaktpersonDialog(false);
        setKontaktpersonDeleteDialog(false);
    };

    const fullKontaktpersonList =
        kontaktpersoner?.map((kp) => ({
            ...kp,
            beskrivelse:
                metaData?.rolletyper.find((rt) => rt.id === kp.rolleId)
                    ?.beskrivelse || '',
        })) || [];

    const columns = [
        {title: 'Navn', field: 'navn'},
        {title: 'Telefon', field: 'telefon'},
        {title: 'E-post', field: 'epost'},
        {title: 'Organisasjon', field: 'organisasjon'},
        {title: 'Rolle', field: 'beskrivelse'},
    ] as GTableColumn[];

    const actions = [
        {
            icon: () => <EditIcon />,
            tooltip: 'Rediger',
            iconProps: {
                style: {color: 'rgba(0, 0, 0, 0.54)'},
                fontSize: 'small',
            },
            onClick: (event, kontaktperson) => {
                handleEdit(kontaktperson);
            },
        },
        {
            icon: () => <DeleteIcon />,
            tooltip: 'Slett',
            iconProps: {
                style: {color: 'rgba(0, 0, 0, 0.54)'},
                fontSize: 'small',
            },
            onClick: (event, kontaktperson) => {
                handleDelete(kontaktperson);
            },
        },
    ] as GTableAction[];
    return (
        <>
            <h1>Kontaktpersoner</h1>
            <ActionButton
                title={
                    <>
                        <AddIcon /> Legg til kontakperson
                    </>
                }
                label={'Ny kontaktperson'}
                onClick={handleAddButton}
            />
            <GenericTable
                title='Kontaktpersoner'
                data={fullKontaktpersonList}
                idColumn='id'
                columns={columns}
                actions={actions}
            />
            <KontaktpersonDialog
                kontaktperson={kontaktperson}
                isOpen={kontaktpersonDialog}
                handleClose={handleDialogClose}
            />
            <ConfirmDeleteKontaktpersonDialog
                id={kontaktperson?.id ? kontaktperson.id : 0}
                name={kontaktperson?.navn ? kontaktperson.navn : ''}
                isOpen={kontaktpersonDeleteDialog}
                handleClose={handleDialogClose}
            />
        </>
    );
}

export default Kontaktpersoner;
