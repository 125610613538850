import {Box as MuiBox, styled, CSSObject} from '@mui/material';
import {ToiTagProps} from './ToiTag';

export const StyledTag = styled(MuiBox, {
  shouldForwardProp: (prop) => !['variant', 'size', 'value'].includes(prop.toString()),
})<ToiTagProps>(({theme, color, size}): CSSObject => {
  return {
    width: 'fit-content',
    height: 'fit-content',
    padding: `0 ${theme.toi.spacing['2xs']}`,
    textAlign: 'center',
    backgroundColor: theme.vars.toi.palette.signal.background[color ?? 'normal'],
    borderRadius: '2px',

    '& > p': {
      fontSize: theme.toi.fontSizes[size === 'small' ? 's' : 'm'],
      lineHeight: theme.toi.lineHeight[size === 'small' ? 's' : 'm'],
    },
  };
});
