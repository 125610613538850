import {LinkProps, LinkTypeMap} from '@mui/material';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import React, {ReactNode} from 'react';
import {getSecureUrl} from '@norkart/validation-url';
import {StyledLink} from './StyledLink';

export type ToiLinkColor = 'primary' | 'secondary';

export type ToiLinkOwnProps = {
  uppercase?: boolean;
  color?: ToiLinkColor;
  definition?: boolean;
  inline?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
};

type ToiLinkTypeMap = LinkTypeMap<ToiLinkOwnProps>;

export type ToiLinkProps<C extends React.ElementType = 'a'> = LinkProps<C, ToiLinkOwnProps>;

export const ToiLink = React.forwardRef<HTMLAnchorElement, ToiLinkProps>((props, ref) => {
  const {startIcon, endIcon, ...restProps} = props;

  return (
    <StyledLink
      tabIndex={0}
      {...restProps}
      href={'href' in restProps && typeof restProps.href === 'string' ? getSecureUrl(restProps.href) : undefined}
      ref={ref}
    >
      {startIcon && <span className='ToiLink-startIcon'>{startIcon}</span>}
      <span>{props.children}</span>
      {endIcon && <span className='ToiLink-endIcon'>{endIcon}</span>}
    </StyledLink>
  );
}) as OverridableComponent<ToiLinkTypeMap>;

export default ToiLink;
