// eslint-disable-next-line no-restricted-imports
import SyntaxHighlighter, {
    PrismLight,
    SyntaxHighlighterProps,
} from 'react-syntax-highlighter';
import {
    oneDark,
    oneLight,
} from 'react-syntax-highlighter/dist/esm/styles/prism';
import './style.module.css';
import javascript from 'react-syntax-highlighter/dist/esm/languages/prism/javascript';
import typescript from 'react-syntax-highlighter/dist/esm/languages/prism/typescript';
import bash from 'react-syntax-highlighter/dist/esm/languages/prism/bash';
import json from 'react-syntax-highlighter/dist/esm/languages/prism/json';
import tsx from 'react-syntax-highlighter/dist/esm/languages/prism/tsx';
import jsx from 'react-syntax-highlighter/dist/esm/languages/prism/jsx';
import python from 'react-syntax-highlighter/dist/esm/languages/prism/python';
import csharp from 'react-syntax-highlighter/dist/esm/languages/prism/csharp';
import fsharp from 'react-syntax-highlighter/dist/esm/languages/prism/fsharp';
import cpp from 'react-syntax-highlighter/dist/esm/languages/prism/cpp';

import {useTheme} from '@mui/material';

PrismLight.registerLanguage('javascript', javascript);
PrismLight.registerLanguage('typescript', typescript);
PrismLight.registerLanguage('bash', bash);
PrismLight.registerLanguage('json', json);
PrismLight.registerLanguage('tsx', tsx);
PrismLight.registerLanguage('jsx', jsx);
PrismLight.registerLanguage('python', python);
PrismLight.registerLanguage('csharp', csharp);
PrismLight.registerLanguage('fsharp', fsharp);
PrismLight.registerLanguage('cpp', cpp);

export type NkSyntaxHighlighterProps = SyntaxHighlighterProps;

/**
 * If support is needed for other languages than typescript, feel free to import and register new languages in the same way it is done with typescript.
 */
export function NkSyntaxHighlighter({
    children,
    language: languageFromProps,
    ...restProps
}: NkSyntaxHighlighterProps) {
    const mode = useTheme().palette.mode;
    const style = mode === 'dark' ? oneDark : oneLight;

    const fallbackLanguage = 'typescript';
    const supportedLanguages = SyntaxHighlighter.supportedLanguages.concat([
        'jsx',
        'tsx',
    ]);

    const language = (() => {
        if (!languageFromProps || typeof languageFromProps !== 'string') {
            return fallbackLanguage;
        }

        const splitLang = languageFromProps.split('language-');
        if (splitLang.length !== 2) {
            return fallbackLanguage;
        }

        const extractedLang = splitLang[1];
        return supportedLanguages.includes(extractedLang)
            ? extractedLang
            : fallbackLanguage;
    })();

    return (
        <PrismLight
            language={language}
            style={style}
            customStyle={{fontSize: '16px'}}
            {...restProps}
        >
            {children as string}
        </PrismLight>
    );
}
