import {ButtonProps, ButtonTypeMap} from '@mui/material';
import {forwardRef} from 'react';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import {getSecureUrl} from '@norkart/validation-url';
import {StyledButton} from './StyledButton';
import {ToiButtonColor, ToiButtonVariant} from './types';

export type ToiButtonOwnProps = {
  variant?: ToiButtonVariant;
  color?: ToiButtonColor;
  underline?: boolean;
};

// Declares new button variant here to be able to use it as default prop in this file
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    default: true;
    // primary: false; // Enable this when we remove the old toitsu library, new name is default
    // secondary: false; // Enable this when we remove the old toitsu library
    info: false;
    success: false;
    inherit: false;
    warning: false;
  }
  interface ButtonPropsSizeOverrides {
    small: false;
  }
  interface ButtonPropsVariantOverrides {
    // text: false; // TODO: Enable this when we remove the old toitsu library, new name is transparent
    // contained: false; // TODO: Enable this when we remove the old toitsu library, new name is primary (primary = contained in toitsu2)
    // outlined: false; // TODO: Enable this when we remove the old toitsu library, new name is secondary
    primary: true;
    secondary: true;
    tertiary: true;
    transparent: true;
  }
}
// ----------------------------

type DefaultComponent = ButtonTypeMap['defaultComponent'];
type RefType = HTMLElementTagNameMap[DefaultComponent];

export type ToiButtonProps = ButtonProps<DefaultComponent, ToiButtonOwnProps>;

export const ToiButton = forwardRef<RefType, ToiButtonProps>(({href, ...props}, forwardedRef) => {
  return <StyledButton ref={forwardedRef} href={getSecureUrl(href)} disableRipple {...props} />;
}) as OverridableComponent<ButtonTypeMap<ToiButtonOwnProps>>;

export default ToiButton;
