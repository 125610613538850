import {useQuery} from 'react-query';
import {
    kontaktpersonerGetKontaktpersoner,
    KontaktpersonerGetKontaktpersonerResponse,
} from '@norkart/nkapi-arealplaner-api';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import useMeta from '../meta/useMeta';

export function useKontaktpersoner() {
    const kundeId = useSelectedKundeId();
    const {hasKontaktPerson} = useMeta();
    const {authorizedClient} = useArealplanerApiClientContext();

    return useQuery<KontaktpersonerGetKontaktpersonerResponse | undefined>({
        queryKey: ['kontaktpersoner', {kundeId}],
        queryFn: async () => {
            const {data, error} = await kontaktpersonerGetKontaktpersoner({
                client: authorizedClient,
                path: {kundeId},
            });
            if (error) console.log(error);
            return data;
        },
        enabled: hasKontaktPerson(),
    });
}
