import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {
    KontakpersonForCreationDto,
    KontaktpersonDto,
} from '@norkart/nkapi-arealplaner-api';
import useMeta from '../../../hooks/meta/useMeta';
import useCreateKontaktperson from '../../../hooks/kontaktpersoner/useCreateKontaktperson';
import useUpdateKontaktperson from '../../../hooks/kontaktpersoner/useUpdateKontaktpersoner';

type Props = {
    kontaktperson?: KontaktpersonDto;
    isOpen: boolean;
    handleClose: () => void;
};
const KontaktpersonDialog = ({kontaktperson, isOpen, handleClose}: Props) => {
    const {mutateAsync: addKontaktperson} = useCreateKontaktperson();
    const {mutateAsync: updateKontaktperson} = useUpdateKontaktperson();
    const {data: metaData} = useMeta();

    const initialFormData: KontakpersonForCreationDto = {
        navn: '',
        telefon: '',
        epost: '',
        organisasjon: '',
        rolle: undefined,
    };

    const [formData, setFormData] =
        useState<KontakpersonForCreationDto>(initialFormData);

    const [formValidationErrors, setFormValidationErrors] = useState<
        Record<string, string>
    >({});

    const validateForm = (values) => {
        const errors: Record<string, string> = {};
        if (!values.navn) errors.navn = ' Må fylle inn navn';
        if (!values.telefon) errors.telefon = ' Må fylle inn telefon';
        else if (values.telefon.length <= 7)
            errors.telefon = ' Skriv inn et gyldig telefonnummer';
        if (!values.epost) errors.epost = ' Må fylle inn e-post';
        else if (!values.epost.includes('@'))
            errors.epost = ' Skriv inn en gyldig e-post';
        if (!values.organisasjon)
            errors.organisasjon = ' Må fylle inn organisasjon';
        setFormValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    useEffect(() => {
        if (kontaktperson) {
            setFormData({
                navn: kontaktperson.navn,
                telefon: kontaktperson.telefon || 'Ingen telefon',
                epost: kontaktperson.epost || 'Ingen E-post',
                organisasjon:
                    kontaktperson.organisasjon || 'Ingen organisasjon',
                rolle: kontaktperson.rolleId || undefined,
            });
        }
    }, [kontaktperson]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handlePrimRolleChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const selectedValue = parseInt(event.target.value, 10);
        setFormData((prevData) => ({
            ...prevData,
            rolle: isNaN(selectedValue) ? 0 : selectedValue,
        }));
    };

    const handleButton = () => {
        const isFormDataValid = validateForm(formData);
        if (isFormDataValid) {
            if (kontaktperson) {
                updateKontaktperson({
                    kontaktperson: formData,
                    id: kontaktperson.id,
                });
            } else addKontaktperson(formData);
            handleClose();
            setFormData(initialFormData);
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            maxWidth='sm'
            fullWidth={true}
        >
            <DialogTitle>
                {kontaktperson
                    ? 'Endre kontaktperson'
                    : 'Legg til ny kontaktperson'}
            </DialogTitle>
            <DialogContent>
                <form
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        padding: '10px 20px',
                    }}
                >
                    <label>
                        {'Navn:'.padEnd(20, '\u00A0')}
                        <input
                            name='navn'
                            type='text'
                            value={formData.navn}
                            onChange={handleChange}
                        />
                        {formValidationErrors.navn && (
                            <span style={{color: 'red', fontSize: '12px'}}>
                                {formValidationErrors.navn}
                            </span>
                        )}
                    </label>
                    <label>
                        {'Telefon:'.padEnd(20, '\u00A0')}
                        <input
                            name='telefon'
                            type='text'
                            value={formData.telefon}
                            onChange={handleChange}
                        />
                        {formValidationErrors.telefon && (
                            <span style={{color: 'red', fontSize: '12px'}}>
                                {formValidationErrors.telefon}
                            </span>
                        )}
                    </label>
                    <label>
                        {'E-post:'.padEnd(20, '\u00A0')}
                        <input
                            name='epost'
                            type='text'
                            value={formData.epost}
                            onChange={handleChange}
                        />
                        {formValidationErrors.epost && (
                            <span style={{color: 'red', fontSize: '12px'}}>
                                {formValidationErrors.epost}
                            </span>
                        )}
                    </label>
                    <label>
                        {'Organisasjon:'.padEnd(15, '\u00A0')}
                        <input
                            name='organisasjon'
                            type='text'
                            value={formData.organisasjon}
                            onChange={handleChange}
                        />
                        {formValidationErrors.organisasjon && (
                            <span style={{color: 'red', fontSize: '12px'}}>
                                {formValidationErrors.organisasjon}
                            </span>
                        )}
                    </label>
                    <label>
                        {'Primærrolle:'.padEnd(17, '\u00A0')}
                        <select
                            id='primærrolle'
                            value={formData.rolle}
                            onChange={handlePrimRolleChange}
                        >
                            <option />
                            {metaData.rolletyper.map((rt) => (
                                <option key={rt.id} value={rt.id}>
                                    {rt.beskrivelse}
                                </option>
                            ))}
                        </select>
                    </label>
                </form>
            </DialogContent>
            <DialogActions>
                <div style={{display: 'flex', gap: '10px'}}>
                    <Button
                        onClick={handleButton}
                        variant='contained'
                        color='secondary'
                    >
                        {kontaktperson ? 'Endre' : 'Legg til'}
                    </Button>
                    <Button
                        onClick={handleClose}
                        variant='outlined'
                        color='primary'
                    >
                        Avbryt
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default KontaktpersonDialog;
