import {FormHelperText, FormHelperTextProps, styled} from '@mui/material';

export type ToiFormHelperTextProps = FormHelperTextProps;

export const StyledFormHelperText = styled(FormHelperText)(({theme}) => ({
  ...theme.typography.body3,
  marginLeft: 0,
  marginRight: 0,
  color: theme.vars.toi.palette.text.placeholder,

  '&.Mui-error': {
    color: theme.vars.toi.palette.text.error,
  },
}));

export function ToiFormHelperText(props: ToiFormHelperTextProps) {
  return <StyledFormHelperText {...props} />;
}

export default ToiFormHelperText;
