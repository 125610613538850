import {DividerProps, DividerTypeMap} from '@mui/material';
import {CSSProperties, forwardRef} from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {SemanticColors} from '@norkart/toi-colors';
import {ToiTypography} from '../ToiTypography';
import {StyledDivider} from './StyledDivider';

export type DividerSize = 'small' | 'medium';

type OwnProps = {
  size?: DividerSize;
  color?: keyof SemanticColors['divider'] | CSSProperties['color'];
  text?: string;
};

type DefaultComponent = DividerTypeMap['defaultComponent'];
type RefType = HTMLElementTagNameMap[DefaultComponent];

export type ToiDividerProps = DividerProps<DefaultComponent, OwnProps>;

export const ToiDivider = forwardRef<RefType, ToiDividerProps>((props, ref) => {
  const {...restProps} = props;

  return (
    <StyledDivider ref={ref} {...restProps}>
      {props.text && (
        <ToiTypography variant='body3' fontWeight={'bold'}>
          {props.text}
        </ToiTypography>
      )}
    </StyledDivider>
  );
});

export default ToiDivider;
