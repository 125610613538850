import './variables.css';
import {toiFocus as focus} from './toiFocus';

const spacing = {
  '2xs': 'var(--toi-spacing-2xs)',
  xs: 'var(--toi-spacing-xs)',
  s: 'var(--toi-spacing-s)',
  m: 'var(--toi-spacing-m)',
  l: 'var(--toi-spacing-l)',
  xl: 'var(--toi-spacing-xl)',
  '2xl': 'var(--toi-spacing-2xl)',
  '3xl': 'var(--toi-spacing-3xl)',
  '4xl': 'var(--toi-spacing-4xl)',
  '5xl': 'var(--toi-spacing-5xl)',
  '6xl': 'var(--toi-spacing-6xl)',
  '7xl': 'var(--toi-spacing-7xl)',
};

const fontSizes = {
  '4xl': 'var(--toi-font-size-4xl)',
  '3xl': 'var(--toi-font-size-3xl)',
  xxl: 'var(--toi-font-size-xxl)',
  xl: 'var(--toi-font-size-xl)',
  l: 'var(--toi-font-size-l)',
  m: 'var(--toi-font-size-m)',
  s: 'var(--toi-font-size-s)',
  xs: 'var(--toi-font-size-xs)',
};

const lineHeight = {
  '4xl': 'var(--toi-font-line-height-4xl)',
  '3xl': 'var(--toi-font-line-height-3xl)',
  xxl: 'var(--toi-font-line-height-xxl)',
  xl: 'var(--toi-font-line-height-xl)',
  l: 'var(--toi-font-line-height-l)',
  m: 'var(--toi-font-line-height-m)',
  s: 'var(--toi-font-line-height-s)',
  xs: 'var(--toi-font-line-height-xs)',
};

const shadow = {
  0: 'var(--toi-shadow-0)',
  2: 'var(--toi-shadow-2)',
  4: 'var(--toi-shadow-4)',
  8: 'var(--toi-shadow-8)',
  16: 'var(--toi-shadow-16)',
  32: 'var(--toi-shadow-32)',
};

const radius = {
  modal: 'var(--toi-radius-modal)',
  borderRadius: 'var(--toi-border-radius)',
  table: 'var(--toi-radius-table)',
};

export const toiVariables = {
  spacing,
  fontSizes,
  lineHeight,
  shadow,
  radius,
  focus,
  iconSize: 'var(--toi-icon-size)',
  textMaxWidth: 'var(--toi-text-max-width)',
  visualContentMaxWidth: 'var(--toi-visual-content-max-width)',
} as const;

export type ToiVariables = typeof toiVariables;
