import {IconButtonProps as MuiIconButtonProps, IconButtonTypeMap as MuiIconButtonTypeMap} from '@mui/material';
import React from 'react';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import {StyledIconButton} from './StyledIconButton';

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    transparent: true;
    map: true;
  }
}

type OwnProps = {
  'aria-label': React.ComponentProps<'button'>['aria-label'];
  color?: 'default' | 'transparent' | 'error';
  size?: 'small' | 'medium';
  /** @deprecated Add the icon as a child component instead*/
  Icon?: React.ReactNode;
};

type DefaultComponent = MuiIconButtonTypeMap['defaultComponent'];

type RefType = HTMLElementTagNameMap[DefaultComponent];

export type ToiIconButtonProps = Omit<MuiIconButtonProps<DefaultComponent, OwnProps>, 'size' | 'color'>;

export const ToiIconButton = React.forwardRef<RefType, ToiIconButtonProps>((props, ref) => {
  const {children, Icon, ...rest} = props;

  const icon = Icon ?? children;

  return (
    <StyledIconButton ref={ref} {...rest}>
      {icon}
    </StyledIconButton>
  );
}) as OverridableComponent<MuiIconButtonTypeMap<OwnProps>>;
