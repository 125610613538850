import React from 'react';
import {styled} from '@mui/material';
import {ToiTag, ToiTagProps} from '../../ToiTag';

const StyledToiTag = styled(ToiTag)(({theme}) => ({
  position: 'absolute',
  top: theme.toi.spacing['2xs'],
  right: theme.toi.spacing['2xs'],
  zIndex: 1,
}));

export const ToiCardTag = (tag: ToiTagProps & {ref?: React.Ref<HTMLDivElement>}) => {
  return <StyledToiTag data-mui-color-scheme='dark' size='small' {...tag} />;
};
