import {Button} from '@mui/material';
import {CSSObject, styled} from '@mui/material/styles';
import {ToiButtonProps} from './ToiButton';

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => !['underline'].includes(prop.toString()),
})<ToiButtonProps>(({theme, underline, startIcon, endIcon}) => {
  return {
    textTransform: 'none',
    gap: theme.toi.spacing.xs,
    borderRadius: `4px`,
    border: 'none',
    boxShadow: 'none',
    transition: 'none',
    ...theme.typography.body2,
    fontWeight: 600,
    height: 48,
    padding: `0 ${theme.toi.spacing.m}`,

    '&:hover, &:focus, &:active, &:target': {
      boxShadow: 'none',
      transition: 'none',
    },
    ...(underline && {...underlineStyle}),

    '& .MuiButton-icon': {
      marginInline: 0,
    },

    '&.MuiButton-sizeMedium': {
      ...theme.typography.body3,
      color: theme.vars.toi.palette.button.primary.text,
      fontWeight: 600,
      height: 38,
      paddingInline: theme.toi.spacing.s,
    },

    // --- Primary color (default) ----
    color: theme.vars.toi.palette.button.primary.text,
    backgroundColor: theme.vars.toi.palette.button.primary.fill,
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: theme.vars.toi.palette.button.primary.hover,
    },
    '--toi-palette-icons-secondary': theme.vars.toi.palette.button.primary.text,
    // ---------------------------------

    '&.MuiButton-colorSecondary': {
      color: theme.vars.toi.palette.button.secondary.text,
      backgroundColor: theme.vars.toi.palette.button.secondary.fill,
      '&:hover, &.Mui-focusVisible': {
        backgroundColor: theme.vars.toi.palette.button.secondary.hover,
      },
      '--toi-palette-icons-secondary': theme.vars.toi.palette.button.secondary.text,
    },

    '&.MuiButton-secondary': {
      color: theme.vars.toi.palette.button.secondary.text,
      '--toi-palette-icons-secondary': theme.vars.toi.palette.button.secondary.text,
      backgroundColor: theme.vars.toi.palette.backgrounds.component.fill,
      boxShadow: `inset 0px 0px 0px 1px ${theme.vars.toi.palette.button.secondary.fill}`,
      border: 'none',

      '&:hover, &.Mui-focusVisible': {
        boxShadow: `inset 0px 0px 0px 2px ${theme.vars.toi.palette.button.secondary.hover}`,
        backgroundColor: theme.vars.toi.palette.backgrounds.component.fill,
      },

      '&.MuiButton-colorError': {
        color: theme.vars.toi.palette.text.error,
        backgroundColor: theme.vars.toi.palette.backgrounds.component.fill,
        boxShadow: `inset 0px 0px 0px 1px ${theme.vars.toi.palette.border.error}`,
        '&:hover, &.Mui-focusVisible': {
          boxShadow: `inset 0px 0px 0px 2px ${theme.vars.toi.palette.border.error}`,
          backgroundColor: theme.vars.toi.palette.backgrounds.component.fill,
        },
        '--toi-palette-icons-secondary': theme.vars.toi.palette.text.error,
      },
    },

    '&.MuiButton-tertiary': {
      color: theme.vars.toi.palette.button.tertiary.text,
      backgroundColor: theme.vars.toi.palette.button.tertiary.fill,
      '&:hover, &.Mui-focusVisible': {
        backgroundColor: theme.vars.toi.palette.button.tertiary.hover,
      },
      '--toi-palette-icons-secondary': theme.vars.toi.palette.button.tertiary.text,

      '&.MuiButton-colorError': {
        color: theme.vars.toi.palette.text.error,
        backgroundColor: theme.vars.toi.palette.error.fillInverted,
        '--toi-palette-icons-secondary': theme.vars.toi.palette.text.error,
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: theme.vars.toi.palette.error.hoverInverted,
        },
      },
    },

    '&.MuiButton-transparent': {
      color: theme.vars.toi.palette.button.tertiary.text,
      background: 'none',
      border: 'none',
      '--toi-palette-icons-secondary': theme.vars.toi.palette.button.tertiary.text,
      '&:hover, &.Mui-focusVisible': {
        backgroundColor: theme.vars.toi.palette.button.tertiary.hover,
      },

      ...(!startIcon &&
        !endIcon && {
          ...underlineStyle,
        }),
    },

    '&.MuiButton-colorError': {
      color: theme.vars.toi.palette.text.errorInverted,
      backgroundColor: theme.vars.toi.palette.error.fill,
      '&:hover, &.Mui-focusVisible': {
        backgroundColor: theme.vars.toi.palette.error.hover,
      },
      '--toi-palette-icons-secondary': theme.vars.toi.palette.text.errorInverted,

      '&.MuiButton-transparent': {
        color: theme.vars.toi.palette.text.error,
        background: 'none',
        border: 'none',
        '--toi-palette-icons-secondary': theme.vars.toi.palette.text.error,
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: theme.vars.toi.palette.error.hoverInverted,
        },
      },
    },

    '&.Mui-focusVisible': {
      ...theme.toi.focus,
    },

    '&.Mui-disabled': {
      color: theme.vars.toi.palette.disabled.text,
      backgroundColor: theme.vars.toi.palette.disabled.fill,
      boxShadow: 'none',
      '--toi-palette-icons-secondary': theme.vars.toi.palette.disabled.text,
    },
  } satisfies CSSObject;
});

const underlineDecoration = {
  textDecoration: 'underline',
  textDecorationThickness: '1px',
  textUnderlineOffset: '5px',
};

const underlineStyle = {
  ...underlineDecoration,
  '&:hover, &:focus, &:active, &:target': {
    ...underlineDecoration,
  },
};
