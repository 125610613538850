import * as React from 'react';
import {useEffect} from 'react';
import {
    Grid,
    IconButton,
    Tooltip,
    Autocomplete,
    TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useMeta from '../../../hooks/meta/useMeta';
import {useKontaktpersoner} from '../../../hooks/kontaktpersoner/useKontaktpersoner';
import {Arealplan} from '../../../hooks/arealplaner/types';
import {useOrganisasjonsPersoner} from '../../../hooks/organisasjonspersoner/useOrganisasjonsPersoner';

export type Option = {
    id: number;
    rolleId: number;
};

interface Props {
    plan: Arealplan;
    setEditOrganisasjonsPersonOpen: (value: boolean) => void;
    setSelectedValuesOrganisasjonsPerson: (value: Option[]) => void;
    selectedValuesOrganisasjonsPerson: Option[];
}

const OrganisasjonspersonPlanForm = ({
    plan,
    setEditOrganisasjonsPersonOpen,
    setSelectedValuesOrganisasjonsPerson,
    selectedValuesOrganisasjonsPerson,
}: Props) => {
    const meta = useMeta();

    const {data: kontaktpersoner} = useKontaktpersoner();

    const {data: organisasjonspersoner} = useOrganisasjonsPersoner(plan.id);

    const getKontaktpersonName = (id: number): string => {
        const kontaktperson = kontaktpersoner?.find(
            (person) => person.id === id
        );
        return kontaktperson?.navn || '';
    };

    useEffect(() => {
        if (organisasjonspersoner) {
            const organisasjonsPersonVerdier = organisasjonspersoner.map(
                (person) => ({
                    id: person.kontaktperson?.id ?? 0,
                    rolleId: person.rolle?.id ?? 0,
                })
            );
            setSelectedValuesOrganisasjonsPerson(organisasjonsPersonVerdier);
        }
    }, [organisasjonspersoner, setSelectedValuesOrganisasjonsPerson]);

    const handleChangeOrganisasjonsPerson = (event, newValue) => {
        setSelectedValuesOrganisasjonsPerson(newValue);
    };

    const optionsList =
        kontaktpersoner?.filter(
            (op) =>
                op.id &&
                op.rolleId &&
                !organisasjonspersoner?.some(
                    (selected) =>
                        selected.kontaktperson?.id === op.id &&
                        selected.rolle?.id === op.rolleId
                )
        ) || [];

    return (
        <Grid
            item={true}
            xs={2}
            md={6}
            sx={{
                marginTop: '20px',
            }}
        >
            <Autocomplete
                size='medium'
                options={optionsList}
                freeSolo
                getOptionLabel={(option) => {
                    if (typeof option === 'string') return option;
                    return `${
                        option.id ? getKontaktpersonName(option.id) : ''
                    } - ${
                        option.rolleId
                            ? meta.getRolletypeBeskrivelse(option.rolleId)
                            : ''
                    }`;
                }}
                value={selectedValuesOrganisasjonsPerson}
                onChange={handleChangeOrganisasjonsPerson}
                multiple={true}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label='Velg kontaktperson'
                        variant='outlined'
                        fullWidth
                        size='small'
                    />
                )}
            />
            <Tooltip title='Endre kontaktperson'>
                <IconButton
                    onClick={() => setEditOrganisasjonsPersonOpen(true)}
                    size='small'
                >
                    <AddIcon fontSize='small' />
                </IconButton>
            </Tooltip>
        </Grid>
    );
};

export default OrganisasjonspersonPlanForm;
