import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {CloseIcon} from '@norkart/toi-icons';
import {ToiBox} from '@norkart/toi-deprecated-components';
import {useKontaktpersoner} from '../../../hooks/kontaktpersoner/useKontaktpersoner';
import useMeta from '../../../hooks/meta/useMeta';
import StyledSelect from '../../../components/StyledSelect/StyledSelect';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onOk: (values) => void;
};

const EditOrganisasjonspersonDialog = ({isOpen, onClose, onOk}: Props) => {
    const {data: kontaktpersoner} = useKontaktpersoner();
    const {data: metaData} = useMeta();
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedNameValue, setSelectedNameValue] = useState<string | number>(
        ''
    );
    const [selectedRolleValue, setSelectedRolleValue] = useState<
        string | number
    >('');

    useEffect(() => {
        setErrorMessage('');
        setSelectedNameValue('');
        setSelectedRolleValue('');
    }, [isOpen]);

    const handleSubmit = () => {
        if (selectedNameValue !== 0 && selectedRolleValue !== 0) {
            onOk([
                {
                    kontaktperson: selectedNameValue,
                    rolletype: selectedRolleValue,
                },
            ]);
        } else setErrorMessage('Må fylle ut navn og rolle');
    };
    const handleNameChange = (e) => {
        setSelectedNameValue(e.target.value);
    };
    const handleRolleChange = (e) => {
        setSelectedRolleValue(e.target.value);
    };

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth='sm' fullWidth={true}>
            <DialogTitle id='edit-organisasjonperson-title'>
                <Grid
                    container={true}
                    justifyContent='space-between'
                    spacing={8}
                    alignItems='center'
                >
                    <Grid item={true}>Legg til kontaktperson</Grid>
                    <IconButton onClick={onClose}>
                        <CloseIcon fontSize='small' />
                    </IconButton>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <ToiBox
                    sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                    }}
                >
                    <ToiBox
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                        }}
                    >
                        <Typography>Kontaktperson</Typography>
                        <StyledSelect
                            sx={{
                                minWidth: '80%',
                            }}
                            value={selectedNameValue}
                            onChange={handleNameChange}
                            options={kontaktpersoner}
                            valueCol='navn'
                            keyCol='id'
                        />
                    </ToiBox>
                    <ToiBox
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                        }}
                    >
                        <Typography>Rolle</Typography>
                        <StyledSelect
                            sx={{
                                minWidth: '80%',
                            }}
                            value={selectedRolleValue}
                            onChange={handleRolleChange}
                            options={metaData.rolletyper}
                            valueCol='beskrivelse'
                            keyCol='id'
                        />
                    </ToiBox>
                </ToiBox>
                <ToiBox sx={{color: 'red', marginTop: 2}}>
                    {errorMessage}
                </ToiBox>
            </DialogContent>
            <DialogActions>
                <div style={{display: 'flex', gap: '10px'}}>
                    <Button
                        onClick={onClose}
                        variant='outlined'
                        color='primary'
                    >
                        Avbryt
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant='contained'
                        color='secondary'
                    >
                        Legg til
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default EditOrganisasjonspersonDialog;
