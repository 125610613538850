import {BoxProps as MuiBoxProps} from '@mui/material';
import {forwardRef} from 'react';
import {ToiTypography} from '../ToiTypography';
import {StyledTag} from './StyledTag';

export type ToiTagColors = 'normal' | 'info' | 'success' | 'warning' | 'error';

/** @deprecated Use ToiTagColors instead */
export type ToiTagVariants = ToiTagColors;

type ToiTagSize = 'small' | 'default';

type OwnProps = {
  color?: ToiTagColors;
  size?: ToiTagSize;
  value: string;
  /** @deprecated Use color prop instead */
  variant?: ToiTagColors;
};

export type ToiTagProps = Omit<MuiBoxProps, keyof OwnProps | 'component'> & OwnProps;

export const ToiTag = forwardRef<HTMLElement, ToiTagProps>(
  ({color = 'normal', value, size, ...props}, forwardedRef) => {
    return (
      <StyledTag className='ToiTag-root' color={color} size={size} value={value} ref={forwardedRef} {...props}>
        <ToiTypography className='ToiTag-value'>{value}</ToiTypography>
      </StyledTag>
    );
  },
);

export default ToiTag;
