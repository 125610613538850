import {Dialog, DialogProps, styled} from '@mui/material';
import React, {forwardRef, useCallback} from 'react';
import {ToiCloseButton} from '../ToiCloseButton/ToiCloseButton';

type OwnProps = {
  onClose?: (event: object, reason: 'backdropClick' | 'escapeKeyDown' | 'closeButton') => void;
  hasCloseButton?: boolean;
  closeIconButtonAriaLabel?: string;
};

export type ToiDialogProps = Omit<DialogProps, keyof OwnProps> & OwnProps;

export const ToiDialog = forwardRef<HTMLDivElement, ToiDialogProps>((props, ref) => {
  const {children, hasCloseButton = true, closeIconButtonAriaLabel = 'Lukk', onClose, ...restProps} = props;

  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClose && onClose(event, 'closeButton'),
    [onClose],
  );

  return (
    <StyledDialog ref={ref} onClose={onClose} {...restProps}>
      {onClose && hasCloseButton && (
        <ToiCloseButton
          sx={{position: 'absolute', top: 8, right: 8}}
          onClick={handleClose}
          aria-label={closeIconButtonAriaLabel}
        />
      )}
      {children}
    </StyledDialog>
  );
});

const StyledDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialog-paper': {
    padding: theme.toi.spacing.l,
    borderRadius: theme.toi.radius.modal,
    boxShadow: theme.toi.shadow[32],
    maxWidth: '90vw',
  },

  '& .MuiDialogContent-root': {
    padding: 0,
  },

  '& .MuiDialogActions-root': {
    justifyContent: 'flex-start',
    gap: theme.toi.spacing.s,
    padding: 0,
    paddingTop: theme.toi.spacing.s,
  },

  '& .ToiDialog-description': {
    marginTop: theme.toi.spacing.xs,
  },
}));

export default ToiDialog;
