import {Grid, Typography} from '@mui/material';
import {setTitle} from '../../../../utils/setTitle';
import KostraTable from './KostraTable';

export default function Kostra() {
    setTitle('Rapportert > Kostra');
    return (
        <Grid direction='column' container={true}>
            <Typography
                variant='h2'
                fontSize={48}
                sx={{marginTop: 20, marginBottom: 16}}
            >
                Kostra
            </Typography>
            <KostraTable />
        </Grid>
    );
}
