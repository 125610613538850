import {Components} from '@mui/material';

const LIST_INSET_WIDTH = 48;
const MENU_INSET_WIDTH = 40;

export const componentOverrides: Components = {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        subtitle1: 'h5',
        subtitle2: 'h6',
        label: 'p',
        body3: 'p',
        body4: 'p',
      },
      variant: 'body2',
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      size: 'large',
    },
  },
  MuiFormControlLabel: {
    defaultProps: {
      slotProps: {
        typography: {variant: 'label'},
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        display: 'flex',
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiLink: {
    defaultProps: {
      variant: 'body2',
    },
  },
  MuiCircularProgress: {
    defaultProps: {
      size: 'default',
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: 4,
        boxShadow: 'var(--toi-shadow-2)',
      },
    },
  },
  // --- List and menu components ---
  MuiList: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        minWidth: 200,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '& .MuiListItemText-inset': {
          paddingLeft: MENU_INSET_WIDTH,
        },
        '& .MuiListItemIcon-root': {
          minWidth: MENU_INSET_WIDTH - 4,
          marginLeft: 4,
        },
        '& .MuiListItemAvatar-root': {
          minWidth: MENU_INSET_WIDTH,
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        paddingBlock: 'var(--toi-spacing-xs)',
        paddingInline: 0,
        '& .MuiListItemText-inset': {
          paddingLeft: LIST_INSET_WIDTH,
        },
        '& .MuiListItemIcon-root': {
          minWidth: LIST_INSET_WIDTH - 4,
          marginLeft: 4,
        },
        '& .MuiListItemAvatar-root': {
          minWidth: LIST_INSET_WIDTH,
        },
        '& .MuiListItemText-root .MuiTypography-root': {
          textOverflow: 'ellipsis',
          marginRight: 'var(--toi-spacing-s)',
        },
        '& .MuiListItemText-primary': {
          fontWeight: 600,
        },
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      secondary: {
        color: 'inherit',
      },
    },
    defaultProps: {
      primaryTypographyProps: {variant: 'body2', noWrap: true},
      secondaryTypographyProps: {variant: 'body3', noWrap: true},
    },
  },
  MuiListItemSecondaryAction: {
    defaultProps: {
      onClick: (event: React.MouseEvent<HTMLElement>) => event.stopPropagation(),
    },
    styleOverrides: {
      root: {
        position: 'unset',
        display: 'flex',
        transform: 'unset',
        gap: 'var(--toi-spacing-xs)',
      },
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        padding: 'var(--toi-spacing-s)',
      },
    },
  },
  MuiCardMedia: {
    styleOverrides: {
      root: {
        margin: 'var(--toi-spacing-2xs)',
        borderRadius: 'calc(var(--toi-spacing-2xs) / 2)',
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: 'var(--toi-spacing-s)',

        '&:last-child': {
          paddingBottom: 'var(--toi-spacing-s)',
        },
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      spacer: {
        flex: 'none',
      },
    },
  },
  // ---------------------------------
};
