import {useMutation, useQueryClient} from 'react-query';
import {
    KontakpersonForCreationDto,
    KontaktpersonDto,
    kontaktpersonerCreateKontaktperson,
} from '@norkart/nkapi-arealplaner-api';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import useErrorHandler from '../errors/useErrorHandler';

export default function useCreateKontaktperson() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const {authorizedClient} = useArealplanerApiClientContext();
    const {notify} = useErrorHandler();

    return useMutation(
        async (
            kontaktperson: KontakpersonForCreationDto
        ): Promise<KontaktpersonDto> => {
            const {data, error} = await kontaktpersonerCreateKontaktperson({
                client: authorizedClient,
                path: {kundeId},
                body: kontaktperson,
            });
            if (error || !data) {
                throw new Error('Error creating kontaktperson');
            }
            return data;
        },
        {
            onError: (error: Error) => {
                notify(error.message);
            },
            onSuccess: () => {
                queryClient.invalidateQueries(['kontaktpersoner', {kundeId}]);
            },
        }
    );
}
