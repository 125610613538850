import {IconButton, styled} from '@mui/material';

export const StyledIconButton = styled(IconButton)(({theme}) => ({
  height: 24,
  width: 24,
  padding: 0,
  borderRadius: 2,

  '&:focus': {
    outline: 1,
    outlineStyle: 'dashed',
    outlineColor: theme.vars.toi.palette.border.hover,
  },
}));
