import {useMutation, useQueryClient} from 'react-query';
import {kontaktpersonerDeleteKontaktperson} from '@norkart/nkapi-arealplaner-api';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';
import useErrorHandler from '../errors/useErrorHandler';

export default function useDeleteKontaktperson() {
    const queryClient = useQueryClient();
    const kundeId = useSelectedKundeId();
    const {authorizedClient} = useArealplanerApiClientContext();
    const {notify} = useErrorHandler();

    return useMutation(
        async (id: number) => {
            const {data, error} = await kontaktpersonerDeleteKontaktperson({
                client: authorizedClient,
                path: {kundeId, id},
            });
            if (error) {
                throw new Error('Error deleting kontaktperson');
            }
            return data;
        },
        {
            onError: (error: Error) => {
                notify(error.message);
            },
            onSuccess: () => {
                queryClient.invalidateQueries(['kontaktpersoner', {kundeId}]);
            },
        }
    );
}
