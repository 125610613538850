import React, {useEffect, useRef} from 'react';
import {ToiBox} from '@norkart/toi-components';
import {ResponseToUser} from '../types';

type Props = {children: React.ReactNode; chatHistory?: ResponseToUser[]};
export const ChatArea = ({children, chatHistory}: Props) => {
    const endRef = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        endRef.current?.scrollIntoView({behavior: 'smooth'});
    };

    useEffect(() => {
        scrollToBottom();
    }, [chatHistory]);

    return (
        <ToiBox
            overflow={'auto'}
            flexGrow={1}
            display='flex'
            flexDirection={'column'}
        >
            <ToiBox
                m={'auto'}
                width='80vw'
                maxWidth='1200px'
                display='flex'
                flexDirection={'column'}
                justifyContent={'space-between'}
                flexGrow={1}
            >
                {children}
            </ToiBox>
            <div className='End-ref-for-scroll' ref={endRef}></div>
        </ToiBox>
    );
};
export default ChatArea;
