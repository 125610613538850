import {Divider} from '@mui/material';
import {styled} from '@mui/material/styles';
import {DividerSize, ToiDividerProps} from './ToiDivider';

export const StyledDivider = styled(Divider)<ToiDividerProps>(({theme, orientation, color, size}) => {
  const colors = theme.vars.toi.palette;

  const calculateTextColor = () => {
    switch (color) {
      case 'green':
        return colors.links.default;
      case 'black':
        return colors.text.default;
      case 'grey':
      case undefined:
        return colors.text.placeholder;
      default:
        return color;
    }
  };

  const calculateDividerColor = () => {
    switch (color) {
      case 'green':
        return colors.divider.green;
      case 'black':
        return colors.divider.black;
      case 'grey':
      case undefined:
        return colors.divider.grey;
      default:
        return color;
    }
  };

  const dividerColor = calculateDividerColor();
  const textColor = calculateTextColor();

  const lineThickness = (orientation?: 'vertical' | 'horizontal', size?: DividerSize) => {
    if (size === 'medium') {
      if (orientation === 'vertical') return {borderRightWidth: '2px'};
      return {borderBottomWidth: '2px'};
    }

    if (orientation === 'vertical') return {borderRightWidth: '1px'};
    return {borderBottomWidth: '1px'};
  };

  return {
    borderColor: dividerColor,
    // Divider with children is rendered as a div and must be handled
    '&.MuiDivider-withChildren::before, &.MuiDivider-withChildren::after': {
      borderColor: dividerColor,
    },
    color: textColor,
    ...lineThickness(orientation, size),
  };
});
