import {useQuery} from 'react-query';
import {
    arealplanerGetArealplanOrganisasjonsPersoner,
    ArealplanerGetArealplanOrganisasjonsPersonerResponse,
} from '@norkart/nkapi-arealplaner-api';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import useMeta from '../meta/useMeta';

export function useOrganisasjonsPersoner(arealplanId) {
    const kundeId = useSelectedKundeId();
    const {hasKontaktPerson} = useMeta();
    const {authorizedClient} = useArealplanerApiClientContext();

    return useQuery<
        ArealplanerGetArealplanOrganisasjonsPersonerResponse | undefined
    >({
        queryKey: ['organisasjonspersoner', {kundeId}, {arealplanId}],
        queryFn: async () => {
            const {data, error} =
                await arealplanerGetArealplanOrganisasjonsPersoner({
                    client: authorizedClient,
                    path: {arealplanId: arealplanId, kundeId},
                });
            if (error) console.log(error);
            return data;
        },

        enabled: Boolean(arealplanId) && hasKontaktPerson(),
    });
}
