import {useQuery} from 'react-query';
import {
    KostraRapporterGetKostraRapporterResponse,
    kostraRapporterGetKostraRapporter,
} from '@norkart/nkapi-arealplaner-api';
import useSelectedKundeId from '../kunder/useSelectedKundeId';
import {useArealplanerApiClientContext} from '../../services/arealplanerApi';

export default function useKostraRapporter() {
    const kundeId = useSelectedKundeId();
    const {authorizedClient} = useArealplanerApiClientContext();

    return useQuery<KostraRapporterGetKostraRapporterResponse | undefined>({
        queryKey: ['kostra'],
        queryFn: async () => {
            const {data, error} = await kostraRapporterGetKostraRapporter({
                client: authorizedClient,
                path: {kundeId: kundeId},
            });
            if (error) console.log(error);
            return data;
        },
        enabled: !!kundeId,
    });
}
