import * as React from 'react';
import {Dialog, DialogTitle, DialogActions, Button} from '@mui/material';
import useDeleteKontaktperson from '../../../hooks/kontaktpersoner/useDeleteKontaktperson';

export interface DeleteKontaktpersonDialogProps {
    id: number;
    name: string;
    isOpen: boolean;
    handleClose: () => void;
}

const ConfirmDeleteKontaktpersonDialog = ({
    id,
    name,
    isOpen,
    handleClose,
}: DeleteKontaktpersonDialogProps) => {
    const {mutateAsync: deleteKontaktperson} = useDeleteKontaktperson();
    const handleOk = () => {
        deleteKontaktperson(id);
        handleClose();
    };
    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby='delete-dialog-title'
        >
            <DialogTitle id='delete-dialog-title'>
                Vil du virkelig slette {name}?
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleClose}>Nei</Button>
                <Button onClick={handleOk}>Ja</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDeleteKontaktpersonDialog;
