import {IconButton, styled, Theme} from '@mui/material';

export const disabled = (theme: Theme) => ({
  backgroundColor: theme.vars.toi.palette.disabled.fill,
  color: theme.vars.toi.palette.disabled.text,
  '& svg': {
    '--toi-palette-icons-secondary': theme.vars.toi.palette.disabled.text,
  },
  border: 'none',
  '&.MuiIconButton-colorTransparent': {
    backgroundColor: 'transparent',
    '& svg': {
      '--toi-palette-icons-secondary': theme.vars.toi.palette.disabled.textOnWhite,
    },
  },
});

export const StyledIconButton = styled(IconButton)(({theme}) => {
  return {
    width: 32,
    height: 32,
    padding: 0,
    borderRadius: '4px',
    backgroundColor: theme.vars.toi.palette.button.tertiary.fill,

    '& svg': {
      width: '24px',
      height: '24px',
    },

    '&.MuiIconButton-sizeMedium': {
      width: 38,
      height: 38,
    },

    '&.MuiIconButton-colorTransparent': {
      backgroundColor: 'inherit',
    },
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: theme.vars.toi.palette.button.tertiary.hover,
    },
    '&.Mui-focusVisible': {
      outlineOffset: '2px',
      outline: `1px dashed ${theme.vars.toi.palette.focus.border}`,
    },
    '&.MuiIconButton-colorError': {
      color: theme.vars.toi.palette.text.error,
      '--toi-palette-icons-secondary': theme.vars.toi.palette.text.error,
      backgroundColor: theme.vars.toi.palette.error.fillInverted,
      '&:hover, &.Mui-focusVisible': {
        color: theme.vars.toi.palette.text.errorInverted,
        backgroundColor: theme.vars.toi.palette.error.hoverInverted,
      },
    },

    '&.Mui-disabled': disabled(theme),
  };
});
