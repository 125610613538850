import Markdown, {type Options} from 'react-markdown';
import {styled} from '@mui/material';
import {useEffect} from 'react';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import {ToiBox} from '../pureMuiComponents';
import {components} from './Overrides';

export type ToiMarkdownProps = {
  children?: Options['children'];
};

const StyledBox = styled(ToiBox)(({theme}) => ({
  '& .markdown-container': {
    '> :not(:is(h1, h2, h3, h4, h5, h6))': {
      marginBlock: '1em !important',
    },

    '> :is(h1, h2, h3, h4, h5, h6)': {
      marginTop: '1em',
    },
  },

  '& .first-child': {
    marginTop: '0 !important',
  },

  '& > ul, & > ol': {
    marginBottom: theme.toi.spacing.s,
  },

  '& .MuiTypography-root > code': {
    backgroundColor: theme.vars.toi.palette.backgrounds.generic.green,
  },
}));

export const ToiMarkdown = ({children}: ToiMarkdownProps) => {
  useEffect(() => {
    const markdownElement = document.querySelector('.markdown-container');
    if (markdownElement) {
      const firstChild = markdownElement.firstElementChild as HTMLElement;
      if (firstChild) {
        firstChild.classList.add('first-child');
      }
    }
  }, []);

  return (
    <StyledBox>
      <Markdown
        className='markdown-container'
        components={components}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
      >
        {children}
      </Markdown>
    </StyledBox>
  );
};

export default ToiMarkdown;
