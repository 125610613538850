import {colors} from './colors';
import {SemanticColors} from './types';

export const semanticColorsLight: SemanticColors = {
  brand: {
    logo: colors.green[50],
    wordmark: colors.greyscale[80],
  },
  text: {
    default: colors.greyscale[80],
    inverted: colors.greyscale[5],
    placeholder: colors.greyscale[60],
    success: colors.green[70],
    warning: colors.yellow[90],
    error: colors.red[70],
    errorInverted: colors.greyscale[5],
  },
  links: {
    default: colors.green[70],
    hover: colors.green[90],
    black: colors.greyscale[80],
    blackHover: colors.greyscale[100],
  },
  focus: {
    border: colors.greyscale[80],
    inverted: colors.greyscale[5],
  },
  bars: {
    fill: colors.greyscale[30],
    handle: colors.green[80],
    active: colors.green[50],
    warning: colors.yellow[90],
  },
  divider: {
    green: colors.green[60],
    grey: colors.greyscale[30],
    black: colors.greyscale[80],
  },
  backgrounds: {
    generic: {
      default: colors.greyscale[5],
      grey: colors.greyscale[10],
      panel: colors.greyscale[10],
      panelOnGrey: colors.greyscale[5],
      green: colors.green[5],
      blue: colors.blue[5],
      orange: colors.yellow[5],
    },
    component: {
      fill: colors.greyscale[5],
      highlighted: colors.green[5],
      hover: colors.greyscale[20],
      overlay: colors.greyscale[5],
      active: colors.green[60],
      activeHover: colors.green[70],
      selected: colors.green[10],
      warning: colors.yellow[5],
    },
  },
  border: {
    component: colors.greyscale[30],
    hover: colors.greyscale[50],
    input: colors.greyscale[60],
    inputHover: colors.greyscale[80],
    active: colors.green[60],
    error: colors.red[70],
    errorHover: colors.red[90],
  },
  error: {
    fill: colors.red[70],
    fillInverted: colors.red[5],
    hover: colors.red[90],
    hoverInverted: colors.red[10],
  },
  disabled: {
    fill: colors.greyscale[30],
    text: colors.greyscale[60],
    textOnWhite: colors.greyscale[50],
  },
  signal: {
    foreground: {
      normal: colors.greyscale[70],
      info: colors.blue[70],
      success: colors.green[70],
      warning: colors.yellow[90],
      error: colors.red[70],
    },
    background: {
      normal: colors.greyscale[20],
      normalHover: colors.greyscale[30],
      info: colors.blue[10],
      infoHover: colors.blue[20],
      success: colors.green[20],
      successHover: colors.green[30],
      warning: colors.yellow[10],
      warningHover: colors.yellow[20],
      error: colors.red[10],
      errorHover: colors.red[20],
    },
  },
  button: {
    primary: {
      fill: colors.green[70],
      hover: colors.green[80],
      text: colors.greyscale[5],
    },
    secondary: {
      fill: colors.green[80],
      hover: colors.green[90],
      text: colors.greyscale[80],
      bg: colors.greyscale[5],
    },
    tertiary: {
      fill: colors.green[10],
      hover: colors.green[20],
      text: colors.greyscale[80],
    },
    map: {
      fill: colors.greyscale[5],
      hover: colors.green[10],
    },
    offbrand: {
      fill: colors.greyscale[80],
      hover: colors.greyscale[90],
      text: colors.greyscale[5],
    },
  },
  icons: {
    primary: colors.green[60],
    secondary: colors.greyscale[80],
    tertiary: colors.greyscale[40],
    inverted: colors.greyscale[5],
  },
  pictograms: {
    stroke: colors.greyscale[80],
    background: colors.greyscale[30],
    fill: colors.greyscale[5],
    highlight1: colors.green[40],
    highlight2: colors.red[40],
    highlight3: colors.blue[40],
  },
  illustrations: {
    stroke: '#2E2D30',
    white: '#FFFFFF',
    lightGray: '#DCDCDC',
    gray: '#C0C0C0',
    lightGreen: '#C8EBDC',
    green: '#61C496',
    lightBlue: '#AFD6F5',
    blue: '#75ADE5',
    lightRed: '#FEB2B2',
    red: '#F47777',
    lightPurple: '#D4CCFA',
    purple: '#AC98EE',
    lightYellow: '#FFE190',
    yellow: '#FFCC52',
  },
};
