import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const ChevronUpIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title || 'Chevron Up'}</title>
    <desc>{props.desc || 'Chevron Up'}</desc>
    <path
      d='M18 15L12 9L6 15'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </ToiIcon>
));

export default ChevronUpIcon;
