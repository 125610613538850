import {InputLabel, InputLabelProps, styled} from '@mui/material';
import {forwardRef} from 'react';
import {ToiTag, ToiTagProps} from '../ToiTag';

type OwnProps = {
  optional?: boolean;
  TagProps?: Omit<ToiTagProps, 'ref'>;
};

export type ToiInputLabelProps = InputLabelProps<'label', OwnProps>;

const StyledInputLabel = styled(InputLabel)(({theme}) => ({
  ...theme.typography.label,
  transform: 'none',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  position: 'initial',
  marginBottom: theme.toi.spacing['2xs'],

  '&.Mui-focused': {
    color: theme.vars.toi.palette.text.default,
  },

  '&.Mui-error': {
    color: theme.vars.toi.palette.text.error,
  },
}));

export const ToiInputLabel = forwardRef<HTMLLabelElement, ToiInputLabelProps>(
  ({children, optional, TagProps, ...restProps}, ref) => {
    return (
      <StyledInputLabel shrink disableAnimation ref={ref} {...restProps}>
        {children}
        {optional ? <ToiTag value='Valgfri' color='normal' /> : TagProps && <ToiTag {...TagProps} />}
      </StyledInputLabel>
    );
  },
);

export default ToiInputLabel;
