import {CardProps as MuiCardProps, CardTypeMap as MuiCardTypeMap} from '@mui/material';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import {forwardRef} from 'react';

import {ToiTagProps} from '../ToiTag';
import {StyledCard} from './StyledCard';
import {ToiCardTag} from './Subcomponents/ToiCardTag';

type OwnProps = {
  /** @deprecated Use sx or styled api to change the background color */
  grey?: boolean;
  tag?: ToiTagProps & {ref?: React.Ref<HTMLDivElement>};
};

export type ToiCardProps = Omit<MuiCardProps, keyof OwnProps | 'component'> & OwnProps;

type ToiCardType = OverridableComponent<{
  props: Omit<MuiCardTypeMap<OwnProps>['props'], keyof OwnProps> & OwnProps;
  defaultComponent: MuiCardTypeMap<OwnProps>['defaultComponent'];
}>;

export const ToiCard = forwardRef<HTMLDivElement, ToiCardProps>((props, ref) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const {grey, tag, children, ...restProps} = props;

  return (
    <StyledCard ref={ref} {...restProps}>
      {tag && <ToiCardTag {...tag} />}
      {children}
    </StyledCard>
  );
}) as ToiCardType;

export default ToiCard;
